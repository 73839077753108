import type { SVGProps } from 'react';

export function OrderEmptyStatus(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M25.5625 11.9375V16.6875H23.6875V11.9375C23.6875 11.6 23.3875 11.4375 23.1875 11.4375C23.125 11.4375 23.0625 11.45 23 11.475L13.0875 15.2125C12.425 15.4625 12 16.0875 12 16.8V17.6375C10.8625 18.4875 10.125 19.85 10.125 21.3875V16.8C10.125 15.3125 11.0375 13.9875 12.425 13.4625L22.35 9.7125C22.625 9.6125 22.9125 9.5625 23.1875 9.5625C24.4375 9.5625 25.5625 10.575 25.5625 11.9375ZM33.875 25.125V26.375C33.875 26.5386 33.8109 26.6957 33.6964 26.8126C33.5819 26.9294 33.4261 26.9967 33.2625 27H31.4375C30.775 27 30.175 26.5125 30.125 25.8625C30.0875 25.475 30.2375 25.1125 30.4875 24.8625C30.6007 24.7453 30.7368 24.6526 30.8873 24.5902C31.0378 24.5279 31.1996 24.4972 31.3625 24.5H33.25C33.6125 24.5125 33.875 24.7875 33.875 25.125Z"
        fill="currentColor" />
      <path
        d="M31.35 23.1875H32.625C33.3125 23.1875 33.875 22.625 33.875 21.9375V21.3875C33.875 18.8 31.7625 16.6875 29.175 16.6875H14.825C13.7625 16.6875 12.7875 17.0375 12 17.6375C10.8625 18.4875 10.125 19.85 10.125 21.3875V29.8C10.125 32.3875 12.2375 34.5 14.825 34.5H29.175C31.7625 34.5 33.875 32.3875 33.875 29.8V29.5625C33.875 28.875 33.3125 28.3125 32.625 28.3125H31.5375C30.3375 28.3125 29.1875 27.575 28.875 26.4125C28.6125 25.4625 28.925 24.55 29.55 23.9375C30.0125 23.4625 30.65 23.1875 31.35 23.1875ZM24.5 22.9375H15.75C15.2375 22.9375 14.8125 22.5125 14.8125 22C14.8125 21.4875 15.2375 21.0625 15.75 21.0625H24.5C25.0125 21.0625 25.4375 21.4875 25.4375 22C25.4375 22.5125 25.0125 22.9375 24.5 22.9375Z"
        fill="currentColor" />
      <line x1="37.2036" y1="5.89132" x2="33.6094" y2="10.6956" stroke="currentColor" />
      <line y1="-0.5" x2="5" y2="-0.5" transform="matrix(0.00120344 0.999999 -0.897403 -0.441213 30 3)"
            stroke="currentColor" />
      <line y1="-0.5" x2="5" y2="-0.5" transform="matrix(-0.959662 0.281155 0.676611 0.73634 42.0107 11.9844)"
            stroke="currentColor" />
    </svg>

  );
}
